import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HttpClient, provideHttpClient, withInterceptors } from "@angular/common/http";
import { errorInterceptor } from "./modules/fraud/partials/interceptors/error.interceptor";
import { authInterceptor } from "./modules/fraud/partials/interceptors/auth.interceptor";
import { loaderInterceptor } from "./modules/fraud/partials/interceptors/loader.interceptor";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideEnvironmentNgxMask } from "ngx-mask";
import { appConfigInit } from "./app-config-init";
import { dialogOptions, snackBarOptions } from "./mat-options";

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding(), inMemoryScrollingFeature),
    provideHttpClient(withInterceptors([errorInterceptor, authInterceptor, loaderInterceptor])),
    appConfigInit,
    provideAnimationsAsync(),
    provideEnvironmentNgxMask(),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => {
            return new TranslateHttpLoader(http, './assets/i18/', '.json');
          },
          deps: [HttpClient],
        },
        isolate: true,
      })
    ),
    snackBarOptions,
    dialogOptions
  ]
};
