import { APP_INITIALIZER, Provider } from "@angular/core";
import { Router } from "@angular/router";
import { UserControllerService } from "./modules/fraud/partials/services/user-controller.service";
import { TranslateService } from "@ngx-translate/core";
import { catchError, map, Observable, tap } from "rxjs";
import { UserEntity } from "./modules/fraud/api/models/fraud.models";

export const appConfigInit: Provider = {
  provide: APP_INITIALIZER,
  useFactory: (router: Router, userControllerService: UserControllerService, translateService: TranslateService) => {
    return (): Observable<boolean | UserEntity> | Promise<boolean> => {
      const url: URL = new URL(window.location.href);

      const idQueryParams: string | null = url.searchParams.get('telegramUserId');
      const idLocalStorage: string | null = localStorage.getItem('telegramUserId');
      const idTelegram: string | undefined = window.Telegram.WebApp.initDataUnsafe.user?.id.toString();
      // TODO: Нужно уйти от сохранения idTelegram в localStorage
      const id: string | null | undefined = idTelegram ?? idQueryParams ?? idLocalStorage;

      translateService.use('en');

      if (!id) {
        return router.navigate(['401']);
      }

      userControllerService.telegramUserId.set(id);

      return userControllerService.getUser().pipe(
        tap(async (user: UserEntity | undefined) => {
          if (!user) {
            await router.navigate(['401']);
            return;
          }
          const supportLocales = ['en', 'ru'];
          const { locale } = user;
          translateService.addLangs(supportLocales);
          // TODO return
          translateService.use(supportLocales.includes(locale) ? locale : 'en');
        }),
        map(Boolean),
        catchError(() => {
          return router.navigate(['401']);
        }));
    }
  },
  deps: [
    Router,
    UserControllerService,
    TranslateService
  ],
  multi: true
};
