import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from "rxjs";
import { inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const translateService: TranslateService = inject(TranslateService);
  const matSnackBar = inject(MatSnackBar);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const excluded: string[] = ['/api/v1/users/telegram/me/'];
      if (excluded.map(excluded => error.url?.includes(excluded)).every((v) => v == false) && environment.devMode) {
        matSnackBar.open(error.message, translateService.instant('common.buttons.close'));
      }
      return throwError(() => error);
    })
  );
};
