import { AfterViewInit, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Telegram } from '@twa-dev/types';
import { environment } from "../environments/environment";

declare global {
  interface Window {
    Telegram: Telegram;
  }
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
  get hasDarkTheme(): boolean {
    return environment.hasDarkTheme;
  }

  ngAfterViewInit(): void {
    this._expandWindowTelegram();
  }

  ngOnInit(): void {
    if (this.hasDarkTheme) {
      const isDarkTheme = JSON.parse(localStorage.getItem('isDarkTheme') ?? 'false');

      document.body.classList.remove('dark', 'light');
      document.body.classList.add(isDarkTheme ? 'dark' : 'light');

      document.documentElement.classList.remove('dark', 'light');
      document.documentElement.classList.add(isDarkTheme ? 'dark' : 'light');
    } else {
      document.body.classList.add('light');
      document.documentElement.classList.add('light');
    }
  }

  private _expandWindowTelegram(): void {
    window.Telegram.WebApp.expand();
  }
}
