import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from "@angular/core";
import { UserControllerService } from "../services/user-controller.service";


export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const userControllerService: UserControllerService = inject(UserControllerService);
  const uuid: string | undefined = userControllerService.user()?.uuid;

  if (uuid) {
    req = req.clone({
      setHeaders: { 'Authorization': uuid }
    });
  }

  return next(req);
};
